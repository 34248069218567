import React from 'react';
import { useState } from 'react';
import image1 from '../../assets/solar-1.jpg';
import image2 from '../../assets/solar-2.jpg';
import image3 from '../../assets/solar-3.jpg';
import image4 from '../../assets/solar-4.jpg';
import './carousel.css';

export default function ImageCarousel() {
   const [currentIndex, setCurrentIndex] = useState(0);
   const images = [
      { 'image': image1, 'text': 'Residential EV Charges' },
      { 'image': image2, 'text': 'Commercial Solutions' },
      { 'image': image3, 'text': 'Panels Installation' },
      { 'image': image4, 'text': 'Panels Maintainance' }
   ];

   setTimeout(() => {
      setCurrentIndex((currentIndex + 1) % images.length);
      console.log();
   }, 2500);

   return (
      <>
         <section className="image-hover-container full">
            {images.map((data, index) => {
               return (
                  <div className="about-teams-info">
                     <div className="image-wrapper " key={index}>
                        <img src={data.image} alt="images" className="hover-image" />
                        <div className="overlay">
                           <p>text info</p>
                        </div>
                     </div>
                     <div className="about-teams-text">
                        <span>{data.text}</span>
                     </div>
                  </div>
               );
            })}
         </section>
         <section className="image-hover-container small">
            <div className="about-teams-info">
               <div className="image-wrapper">
                  <img src={images[currentIndex].image} alt={`solar ${currentIndex + 1}`} className="hover-image" />
                  <div className="overlay">
                     <span>{images[currentIndex].text}</span>
                  </div>
               </div>
               <div className="about-teams-text">
                  <span>{images[currentIndex].text}</span>
               </div>
            </div>
         </section>
      </>
   );
}
