import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import image1 from '../../../../assets/solar-4.jpg';
import image from '../../../../assets/solar-2.jpg';
import image2 from '../../../../assets/solar-energy.png';
import './homedetail.css';
import { Slide } from 'react-awesome-reveal';
import ImageCarousel from '../../../carousel/carousel';

export default function HomeDetail() {
   return (
      <div className="home-detail-sections">
         <section className="home-detail-section">
            <Slide triggerOnce="true">
               <img src={image} className="home-detail-section-image" />
            </Slide>
            <div className="home-detail-section-data">
               <Slide triggerOnce="true" direction="right">
                  <span className="home-detail-section-data-header">
                     Solar Panels Installation
                     <span className="home-detail-section-data-quote">WITH MOST SUNLIGHT CONVERSION EFFICIENCY</span>
                  </span>
                  <p className="home-detail-section-data-para">
                     Aenean volutpat, sem sit amet ullamcorper gravida, tortor arcu molestie risus, ut bibendum urna enim nulla.
                     Pellentesque porta arcu velit, faucibus kodales dolor rhoncus sed. Curabitur lacinia massa vitae efficitur
                     porttitor. Sed scelerisque vestibulum lectus, at egestas erat varius.
                  </p>
                  <a className="home-detail-section-data-button">
                     <span className="home-detail-section-data-button-info"> Know More Details</span>
                     <BsArrowRight className="home-detail-section-data-button-icon" />
                  </a>
               </Slide>
            </div>
         </section>
         <section className="home-detail-section">
            <div className="home-detail-section-data">
               <Slide triggerOnce="true">
                  <span className="home-detail-section-data-header">
                     The Preventative Maintenance
                     <span className="home-detail-section-data-quote">INSPECTION TO PREVENT EMERGENCY REPAIR</span>
                  </span>
                  <p className="home-detail-section-data-para">
                     Aenean volutpat, sem sit amet ullamcorper gravida, tortor arcu molestie risus, ut bibendum urna enim nulla.
                     Pellentesque porta arcu velit, faucibus kodales dolor rhoncus sed. Curabitur lacinia massa vitae efficitur
                     porttitor. Sed scelerisque vestibulum lectus, at egestas erat varius.
                  </p>
                  <a className="home-detail-section-data-button">
                     <span className="home-detail-section-data-button-info"> Know More Details</span>
                     <BsArrowRight className="home-detail-section-data-button-icon" />
                  </a>
               </Slide>
            </div>

            <Slide triggerOnce="true" direction="right">
               <img src={image1} className="home-detail-section-image" />
            </Slide>
         </section>
         <section className="home-detail-section">
            <Slide triggerOnce="true">
               <img src={image2} className="home-detail-section-image" />
            </Slide>

            <div className="home-detail-section-data">
               <Slide triggerOnce="true" direction="right">
                  <span className="home-detail-section-data-header">
                     Upgrade Or Replacement
                     <span className="home-detail-section-data-quote">GET YOUR PANELS IN GOOD SHAPE REGULARLY</span>
                  </span>
                  <p className="home-detail-section-data-para">
                     Aenean volutpat, sem sit amet ullamcorper gravida, tortor arcu molestie risus, ut bibendum urna enim nulla.
                     Pellentesque porta arcu velit, faucibus kodales dolor rhoncus sed. Curabitur lacinia massa vitae efficitur
                     porttitor. Sed scelerisque vestibulum lectus, at egestas erat varius.
                  </p>
                  <a className="home-detail-section-data-button">
                     <span className="home-detail-section-data-button-info"> Know More Details</span>
                     <BsArrowRight className="home-detail-section-data-button-icon" />
                  </a>
               </Slide>
            </div>
         </section>
         {/* <section className="home-detail-section">
            <div className="home-detail-section-data">
               <span className="home-detail-section-data-header">
                  Solar Panels Installation
                  <span className="home-detail-section-data-quote">WITH MOST SUNLIGHT CONVERSION EFFICIENCY</span>
               </span>
               <p className="home-detail-section-data-para">
                  Aenean volutpat, sem sit amet ullamcorper gravida, tortor arcu molestie risus, ut bibendum urna enim nulla.
                  Pellentesque porta arcu velit, faucibus kodales dolor rhoncus sed. Curabitur lacinia massa vitae efficitur
                  porttitor. Sed scelerisque vestibulum lectus, at egestas erat varius.
               </p>
               <a className="home-detail-section-data-button">
                  <span className="home-detail-section-data-button-info"> Know More Details</span>
                  <BsArrowRight className="home-detail-section-data-button-icon" />
               </a>
            </div>
            <img src={image} />
         </section> */}
      </div>
   );
}
