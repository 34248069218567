import React from 'react';
import { FcPhoneAndroid, FcAddressBook } from 'react-icons/fc';
import { AiFillMail } from 'react-icons/ai';
import './contactinfo.css';
import { Slide } from 'react-awesome-reveal';

export default function ContactInfo() {
   return (
      <section className="contact-middle">
         <Slide triggerOnce="true" direction="down">
            <div className="contact-address">
               <FcAddressBook className="contact-address-icon" />
               <span>Visit the Office</span>
               <span className="contact-street">Vizag, Andhra pradesh</span>
            </div>
         </Slide>
         <Slide triggerOnce="true" direction="down">
            <div className="contact-address">
               <FcPhoneAndroid className="contact-address-icon" />
               <span>Phone Inquiry</span>
               <span className="contact-street"> +91 9123456789</span>
            </div>
         </Slide>
         <Slide triggerOnce="true" direction="down">
            <div className="contact-address">
               <AiFillMail className="contact-address-icon" />
               <span>Send Email</span>
               <span className="contact-street">info@sukisolar.in</span>
            </div>
         </Slide>
      </section>
   );
}
