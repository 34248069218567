import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import './about.css';
import AboutMission from './components/misson/mission';
import HomeContact from '../Home/components/home-contact/homecontact';
import AboutHome from './components/about-home/aboutHome';
import { Slide } from 'react-awesome-reveal';
import AboutTeam from './components/about-team/AboutTeam';

export default function About() {
   return (
      <div className="about-section">
         <Slide triggerOnce="true">
            <section className="about-header">
               <Slide direction="up" delay={1000}>
                  <div className="about-header-prima">
                     <span>Suki Solar</span>
                     <BsArrowRight />
                     <span>Clean Energy</span>
                  </div>
                  <span className="about-header-secondo">About Suki Solar</span>
               </Slide>
            </section>
         </Slide>
         <AboutHome />
         <AboutMission />
         <HomeContact />
         <AboutTeam />
      </div>
   );
}
