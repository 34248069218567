import React from 'react';
import { FcPhoneAndroid } from 'react-icons/fc';
import './homeform.css';
import { Fade, Slide } from 'react-awesome-reveal';

export default function HomeForm() {
   return (
      <div className="home-form">
         <Slide triggerOnce="true" direction="down">
            <section className="home-form-header">
               <Fade duration={200} cascade className="home-form-header-one">
                  Who are Suki Solar
               </Fade>
               <span className="home-form-header-two"> Get Free Consultation</span>
               <span className="home-form-header-three">For Controlling Your Energy Production</span>
            </section>
            <span className="horizontal-rule-white ">
               <hr className="line" />
               <hr className="horizontal-rule-dotted-white" />
               <hr className="horizontal-rule-dotted-white" />
               <hr className="horizontal-rule-dotted-white" />
            </span>
         </Slide>

         <section className="home-form-section">
            <Slide triggerOnce="true" direction="up">
               <div className="home-form-inputs">
                  <input placeholder="Your Name" />
                  <input placeholder="Your Email" />
                  <input placeholder="Phone Number" />
               </div>
               <div className="home-form-picker">
                  <textarea className="input" placeholder="Enter Your request/Query here" />
               </div>
               <div className="home-form-center">
                  <button className="home-form-button">Send Request</button>
                  <section className="home-form-footer">
                     <div className="home-form-footer-one">
                        <span>--- OR IF YOU NEED QUICK ASSISTANCE ---</span>
                     </div>
                     <div className="home-form-footer-two">
                        <span>Call Us 24/7 For Customer Support At</span>
                        <FcPhoneAndroid />
                        <span className="number">+91 9123456789</span>
                     </div>
                  </section>
               </div>
            </Slide>
         </section>
      </div>
   );
}
