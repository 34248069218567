import React from 'react';
import './sukihome.css';
import image1 from '../../../../assets/solar-1.jpg';
import image2 from '../../../../assets/solar-2.jpg';
import gif1 from '../../../../assets/gif1.gif';
import gif2 from '../../../../assets/gif2.gif';
import gif3 from '../../../../assets/gif3.gif';
import { Slide, Fade } from 'react-awesome-reveal';

export default function Sukihome() {
   return (
      <div className="suki-home">
         <Slide triggerOnce="true">
            <section className="home-about-images">
               <img src={image1} alt="image1" className="home-about-image-one" />
               <img src={image2} alt="image2" className="home-about-image-two" />
            </section>
         </Slide>
         <section className="home-about-text">
            <Slide direction="right" triggerOnce="true" cascade>
               <section className="home-about-text-info">
                  <Fade duration={300} cascade className="home-about-text-info-header">
                     Suki solar
                  </Fade>

                  <div className="home-about-text-header-section">
                     <span className="home-about-text-header">The Solar Solutions</span>
                     <span className="home-about-text-quote">Adorn your life with green energy</span>
                  </div>

                  <span className="horizontal-rule">
                     <hr />
                     <hr className="horizontal-rule-dotted" />
                     <hr className="horizontal-rule-dotted" />
                     <hr className="horizontal-rule-dotted" />
                  </span>

                  <span>
                     Nunc quam arcu, pretium quis quam sed, laoreet efficitur leo. Aliquam era volutpat. Integer lobortis sem
                     consequat consequat imperdiet. In nulla sed viverra ut lorem ut, dapibus consectetur diam. Nunc bibendum
                     imperdiet condimentum.
                  </span>

                  <span>
                     Duis lacinia pulvinar turpis lacinia feugiat. Vivamus tellus enim, euismod euismod laoreet nec, aliquet ut
                     metus. Cras feugiat turpis varius pellentesque eleifend. Vivamus porta ipsum diamy ac congue felis imperdiet
                     vitae duis porttitor.
                  </span>
               </section>
            </Slide>
            <Slide direction="up" triggerOnce="true">
               <div className="home-about-text-gifs">
                  <section className="home-gifs">
                     <img src={gif1} alt="gif1" className="home-about-text-gif-one" />
                     <span>Save Energy For World</span>
                  </section>
                  <span className="vertical-line"></span>
                  <section className="home-gifs">
                     <img src={gif2} alt="gif2" className="home-about-text-gif-one" />
                     <span>Residential Solutions</span>
                  </section>
                  <span className="vertical-line"></span>
                  <section className="home-gifs">
                     <img src={gif3} alt="gif3" className="home-about-text-gif-one" />
                     <span>Get Energy Forests</span>
                  </section>
               </div>
            </Slide>
         </section>
      </div>
   );
}
