import React from 'react';
import { useState } from 'react';
import Confetti from '../../../../views/confetti/confetti';
import { Slide } from 'react-awesome-reveal';
import './homecontact.css';
import { Popup } from '../../../../views/popup/popup';

export default function HomeContact() {
   const [inputs, setInputs] = useState({});
   const [showMessage, setShowMessage] = useState(false);
   const [isVisible, setIsVisible] = useState(false);

   const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setInputs((values) => ({ ...values, [name]: value }));
   };

   const handleSubmit = (event) => {
      event.preventDefault();
      console.log(inputs);
   };

   return (
      <div className="home-contact-middle">
         <Slide triggerOnce="true">
            <span className="home-contact-header">We’re Passionated To Build A Renewable, Cleaner and Safe Future</span>
         </Slide>
         <section className="home-contact-form-section">
            <form className="home-contact-form">
               <input
                  type="text"
                  name="username"
                  value={inputs.username || ''}
                  onChange={handleChange}
                  className="home-contact-form-input"
                  placeholder="Your Name"
               />
               <input
                  type="email"
                  name="email"
                  value={inputs.email || ''}
                  onChange={handleChange}
                  className="home-contact-form-input"
                  placeholder="Your Email"
               />
               <button
                  type="submit"
                  className="home-contact-form-input submit"
                  onClick={(event) => {
                     handleSubmit(event);
                     setShowMessage(true);
                     setIsVisible(true);
                  }}
               >
                  Submit
               </button>
            </form>
            {showMessage ? (
               <Popup
                  text="Hello there!"
                  closePopup={() => {
                     setShowMessage(false);
                     setIsVisible(false);
                  }}
               />
            ) : (
               ''
            )}
            {isVisible && <Confetti />}
         </section>
      </div>
   );
}
