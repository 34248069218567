import React from 'react';
import './homeprogram.css';
import ImageCarousel from '../../../carousel/carousel';
import { Fade, Slide } from 'react-awesome-reveal';

export default function HomeProgram() {
   return (
      <div className="home-program">
         <section className="home-program-header">
            <Slide triggerOnce="true">
               <Fade duration={300} cascade className="home-program-quote">
                  Suki solar
               </Fade>
               <div className="home-about-text-header-section">
                  <span className="home-about-text-header">Extensive programes</span>
                  <span className="home-about-text-quote">Adorn your life with green energy</span>
               </div>
               <span className="horizontal-rule">
                  <hr />
                  <hr className="horizontal-rule-dotted" />
                  <hr className="horizontal-rule-dotted" />
                  <hr className="horizontal-rule-dotted" />
               </span>
            </Slide>
         </section>
         <ImageCarousel />
      </div>
   );
}
