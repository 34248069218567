import React, { useState } from 'react';
import { Fade, Slide } from 'react-awesome-reveal';
import './abouthome.css';
import image1 from '../../../../assets/solar-1.jpg';
import image2 from '../../../../assets/solar-2.jpg';
import image3 from '../../../../assets/solar-3.jpg';
import image4 from '../../../../assets/solar-4.jpg';

export default function AboutHome() {
   const [currentIndex, setCurrentIndex] = useState(0);
   const images = [
      { 'image': image1, 'text': 'Residential EV Charges' },
      { 'image': image2, 'text': 'Commercial Solutions' },
      { 'image': image3, 'text': 'Panels Installation' },
      { 'image': image4, 'text': 'Panels Maintainance' }
   ];
   return (
      <div className="about-home">
         <section className="about-home-text">
            <div className="about-home-text-header about-header-text">
               <Slide triggerOnce="true">
                  <Fade duration={300} cascade className="home-about-text-info-header">
                     Who Are Suki solar
                  </Fade>

                  <div className="home-about-text-header-section">
                     <span className="home-about-text-header">Helping Smart Comapnies With Better Integrated Solar Solutions</span>
                     <span className="home-about-text-quote">Adorn your life with green energy</span>
                  </div>

                  <span className="horizontal-rule">
                     <hr />
                     <hr className="horizontal-rule-dotted" />
                     <hr className="horizontal-rule-dotted" />
                     <hr className="horizontal-rule-dotted" />
                  </span>
               </Slide>
            </div>
            <div className="about-home-text-data">
               <Slide triggerOnce="true" direction="right">
                  <p>
                     Nunc quam arcu, pretium quis quam sed, laoreet efficitur leo. Aliquam era volutpat. Integer lobortis sem
                     consequat consequat imperdiet. In nulla sed viverra ut lorem ut dapibus consectetur diam. Nunc bibendum
                     imperdiet condimentum. Duis lacinia pulvinar turpis lacinia feugiat tellus euismod.
                  </p>
                  <p>
                     Laoreet nec aliquet ut metus. Cras feugiat turpis varius pellentesque eleifend ullamco laboris nisi ut aliquip
                     ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                     nulla pariatur.
                  </p>
               </Slide>
            </div>
         </section>
         <>
            <section className="image-hover-container">
               {images.map((data, index) => {
                  return (
                     <div className="about-teams-info team-image">
                        <div className="image-wrapper " key={index}>
                           <img src={data.image} alt="images" className="hover-image" />
                           <div className="overlay">
                              <p>text info</p>
                           </div>
                        </div>
                        <div className="about-teams-text">
                           <span>{data.text}</span>
                        </div>
                     </div>
                  );
               })}
            </section>
         </>
      </div>
   );
}
