import React from 'react';
import gif1 from '../assets/solar-gif1.png';
import gif2 from '../assets/gif1.gif';
import gif3 from '../assets/gif2.gif';
import gif4 from '../assets/gif3.gif';
import { FcAddressBook, FcPhoneAndroid } from 'react-icons/fc';
import { AiFillMail } from 'react-icons/ai';
import { BsArrowRight } from 'react-icons/bs';
import './footer.css';

export default function Footer() {
   return (
      <div className="Footer">
         <section className="footer-solution">
            <div className="footer-sol">
               <img src={gif1} alt="gif" />
               <span>ENERGY EFFICIENCY SOLUTIONS</span>
            </div>
            <div className="footer-sol">
               <img src={gif2} alt="gif" />
               <span>SOLAR PANEL COMMISSIONING</span>
            </div>

            <div className="footer-sol">
               <img src={gif3} alt="gif" />
               <span>BATTERY BACKUP GENERATOR</span>
            </div>
            <div className="footer-sol">
               <img src={gif4} alt="gif" />
               <span>WHOLE HOUSE SURGE PROTECTION</span>
            </div>
         </section>

         <section className="footer-links">
            <div className="footer-contact-us">
               <h2>Contact Us</h2>
               <div className="footer-address">
                  <FcAddressBook className="footer-address-icon" />
                  <section className="footer-address-detail">
                     <span>Visit the Office</span>
                     <span className="street">Vizag, Andhra pradesh</span>
                  </section>
               </div>
               <div className="footer-address">
                  <FcPhoneAndroid className="footer-address-icon" />
                  <section className="footer-address-detail">
                     <span>Phone Inquiry</span>
                     <a href="tel:+919123456789" className="street">
                        +91 9123456789
                     </a>
                  </section>
               </div>
               <div className="footer-address">
                  <AiFillMail className="footer-address-icon" />
                  <section className="footer-address-detail">
                     <span>Send Email</span>
                     <a href="mailto:info@sukisolar.in" className="street">
                        info@sukisolar.in
                     </a>
                  </section>
               </div>
            </div>
            <div className="footer-quick-links">
               <h2>Quick Links</h2>
               <section className="footer-quick">
                  <BsArrowRight />
                  <span>About Suki Solar</span>
               </section>
               <section className="footer-quick">
                  <BsArrowRight />
                  <span>Careers</span>
               </section>
               <section className="footer-quick">
                  <BsArrowRight />
                  <span>Meet Our Team</span>
               </section>
               <section className="footer-quick">
                  <BsArrowRight />
                  <span>Testimonials</span>
               </section>
               <section className="footer-quick">
                  <BsArrowRight />
                  <span>Case Studies</span>
               </section>
            </div>
            <div className="footer-quick-links">
               <h2>Our services</h2>
               <section className="footer-quick">
                  <BsArrowRight />
                  <span>Installation & Monitoring</span>
               </section>
               <section className="footer-quick">
                  <BsArrowRight />
                  <span>After Sales Service</span>
               </section>
               <section className="footer-quick">
                  <BsArrowRight />
                  <span>Free Replacemrnt</span>
               </section>
               <section className="footer-quick">
                  <BsArrowRight />
                  <span>Warrenty Claims</span>
               </section>
               <section className="footer-quick">
                  <BsArrowRight />
                  <span>Energy Equipments</span>
               </section>
            </div>
         </section>

         <section className="footer-down">
            <span>
               @Copyright 2023 By
               <span className="domain">Sukisolar.in</span>
            </span>
            <span>Privacy Policy | Site Map | Terms & Conditions</span>
         </section>
      </div>
   );
}
