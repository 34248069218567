import React from 'react';
import image from '../../../../assets/top-image.jpeg';
import image1 from '../../../../assets/solar-energy.png';
import { BsArrowRight } from 'react-icons/bs';
import { TiSpanner } from 'react-icons/ti';
import { AiOutlineStar } from 'react-icons/ai';
import { PiGlobeHemisphereEastThin } from 'react-icons/pi';
import './mission.css';
import { Fade, Slide } from 'react-awesome-reveal';

export default function AboutMission() {
   return (
      <div className="about-mission">
         <section className="about-mission-text">
            <Slide triggerOnce="true">
               <Fade duration={300} cascade className="about-mission-text-header">
                  Suki Solar's Mission
               </Fade>
               <span className="about-mission-text-quote">Mission That Matters</span>
               <p>
                  Integer lobortis sem consequat consequat imperdiet In nulla viverra ut lorem ut, dapibus consectetur diam. Nun
                  bibendum diet condiment sed ipsum duis lacinia pulvinar dolar turpis.
               </p>
               <div className="about-mission-text-key">
                  <span>
                     <TiSpanner />
                     Flexible Cost-Effective Solutions
                  </span>
                  <span>
                     <AiOutlineStar /> Produce Innovative Design Futuristic Blades
                  </span>
                  <span>
                     <PiGlobeHemisphereEastThin /> We Tailored For Specific Client's Needs
                  </span>
               </div>
               <button className="about-mission-text-button">More Info</button>
            </Slide>
         </section>

         <img src={image} alt="mission" />
         <img src={image1} alt="innovation" />
      </div>
   );
}
