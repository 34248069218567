import React from 'react';
import HomeContact from '../Home/components/home-contact/homecontact';
import HomeForm from '../Home/components/home-form/homeForm';
import { Slide } from 'react-awesome-reveal';
import { BsArrowRight } from 'react-icons/bs';
import './services.css';
import HomeDetail from '../Home/components/home-details/homedetail';
import ServiceHome from './home/serviceHome';

export default function Services() {
   return (
      <div className="services-section">
         <Slide triggerOnce="true">
            <section className="about-header">
               <Slide direction="up" delay={1000}>
                  <div className="about-header-prima">
                     <span>Suki Solar</span>
                     <BsArrowRight />
                     <span>Clean Energy</span>
                  </div>
                  <span className="about-header-secondo">Our Services</span>
               </Slide>
            </section>
         </Slide>
         <ServiceHome />
         <HomeContact />
         <HomeDetail />
         <HomeForm />
      </div>
   );
}
