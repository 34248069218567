import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { FcAddressBook } from 'react-icons/fc';
import Map from './components/maps/map';
import './contact.css';
import ContactInfo from './components/contactinfo/contactinfo';
import HomeForm from '../Home/components/home-form/homeForm';
import { Fade, Slide } from 'react-awesome-reveal';

export default function Contact() {
   return (
      <div className="contact-section">
         <Slide triggerOnce="true">
            <div className="contact-header">
               <Slide direction="up">
                  <div className="contact-header-prima">
                     Suki Solar <BsArrowRight /> Clean Energy
                  </div>
                  <span className="contact-header-secondo">Get In Touch</span>
               </Slide>
            </div>
         </Slide>

         <ContactInfo />
         <HomeForm />
         <Map />
      </div>
   );
}
