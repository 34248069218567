import React, { useState } from 'react';
import image1 from '../../../../assets/solar-1.jpg';
import image2 from '../../../../assets/solar-2.jpg';
import image3 from '../../../../assets/solar-3.jpg';
import image4 from '../../../../assets/solar-4.jpg';
import './aboutteam.css';
import { Fade } from 'react-awesome-reveal';

export default function AboutTeam() {
   const [currentIndex, setCurrentIndex] = useState(0);
   const images = [
      { 'image': image1, 'name': 'Mr. ', 'pos': 'Founder' },
      { 'image': image2, 'name': 'Mr.', 'pos': 'Founder' },
      { 'image': image3, 'name': 'Mr.', 'pos': 'Founder' },
      { 'image': image4, 'name': 'Mr.', 'pos': 'Founder' }
   ];

   return (
      <div className="about-team">
         <section className="about-team-text">
            <Fade duration={300} cascade className="about-team-quote">
               Offering Great Job With Good Hands
            </Fade>
            <span className="about-team-header">Dedicated Team Members</span>
            <span className="horizontal-rule ">
               <hr className="about-rule" />
               <hr className="horizontal-rule-dotted about-rule" />
               <hr className="horizontal-rule-dotted about-rule" />
               <hr className="horizontal-rule-dotted about-rule" />
            </span>
         </section>
         <section className="about-team-images">
            <section className="image-hover-container">
               {images.map((data, index) => {
                  return (
                     <div className="about-teams-info team-image">
                        <div className="image-wrapper " key={index}>
                           <img src={data.image} alt="images" className="hover-image team-image" />
                           <div className="overlay team-overlay">
                              <p>text info</p>
                           </div>
                        </div>
                        <div className="about-teams-text">
                           <span>{data.name}</span>
                           <span>{data.pos}</span>
                        </div>
                     </div>
                  );
               })}
            </section>
            {/* <section className="image-hover-container small">
               <div className="about-teams-info">
                  <div className="image-wrapper">
                     <img src={images[currentIndex].image} alt={`solar ${currentIndex + 1}`} className="hover-image" />
                     <div className="overlay">
                        <span>{images[currentIndex].name}</span>
                     </div>
                     <div className="about-teams-text">
                        <span>{images[currentIndex].name}</span>
                        <span>{images[currentIndex].pos}</span>
                     </div>
                  </div>
               </div>
            </section> */}
         </section>
      </div>
   );
}
