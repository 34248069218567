import React from 'react';
import image from '../../assets/top-image.jpeg';
import Sukihome from './components/Suki-home/sukihome';
import HomeContact from './components/home-contact/homecontact';
import HomeProgram from './components/home-programs/homeprogram';
import HomeForm from './components/home-form/homeForm';
import HomeDetail from './components/home-details/homedetail';
import { Fade, Slide } from 'react-awesome-reveal';
import './home.css';

export default function Home() {
   return (
      <>
         <div className="app">
            <div className="image-contianer">
               <Slide triggerOnce="true">
                  <img src={image} alt="image1" className="top-image" />
               </Slide>
               <Fade triggerOnce="true">
                  <section className="home-image-text">
                     <Slide direction="right" triggerOnce="true" cascade>
                        <Fade duration={150} cascade className="prima">
                           WELCOME TO SUKI SOLAR
                        </Fade>
                        <Fade direction="up" duration={2000} cascade delay={1700}>
                           <span className="secondo"> Take Your Building Or Home Off The Grid By Solar </span>
                        </Fade>
                     </Slide>
                  </section>
               </Fade>
            </div>
            <Sukihome />
            <HomeProgram />
            <HomeContact />
            <HomeDetail />
            <HomeForm />
         </div>
      </>
   );
}
