import React from 'react';
import './popup.css';

export const Popup = ({ text, closePopup }) => {
   return (
      <div className="popup-container">
         <div className="popup-body">
            <span>Thank You! We Will Get Back To You Shortly.</span>
            <button onClick={closePopup}>Close</button>
         </div>
      </div>
   );
};
