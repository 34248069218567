import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import Header from './views/header';
import Home from './components/Home/home';
import About from './components/About/about';
import Services from './components/Services/services';
import Contact from './components/Contact/contact';
import Footer from './views/footer';
// import Popup from "./popup/popup";

ReactDOM.createRoot(document.getElementById('root')).render(
   <React.StrictMode>
      <BrowserRouter>
         <Header />
         {/* <Popup /> */}
         <div className="container">
            <Routes>
               <Route path="/" element={<Home />} />
               <Route path="/services" element={<Services />} />
               <Route path="/about" element={<About />} />
               <Route path="/contact" element={<Contact />} />
               <Route path="/#!/home" element={<Home />} />
            </Routes>
         </div>
         <Footer />
      </BrowserRouter>
   </React.StrictMode>
);
