import React from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';

const Map = () => {
   const mapStyles = {
      height: '700px',
      width: '100%'
   };

   const defaultCenter = {
      lat: 17.7144,
      lng: 83.3241
   };

   return (
      <LoadScript googleMapsApiKey="AIzaSyB7f73BeDgHRJHLRy71FZsfih8HzbvFPX4">
         <GoogleMap mapContainerStyle={mapStyles} center={defaultCenter} zoom={10} />
      </LoadScript>
   );
};

export default Map;
