import React, { useState } from 'react';
import './header.css';
import { Link } from 'react-router-dom';

const Header = () => {
   const [isOpen, setIsOpen] = useState(false);
   return (
      <div className="Navbar">
         <div>
            <span className="nav-logo">
               <Link className="logo" to="/#!/home">
                  <span>Suki Solar</span>
               </Link>
            </span>
         </div>

         <div className={`nav-items ${isOpen && 'open'}`}>
            <Link to="/#!/home" className="a" onClick={() => setIsOpen(false)}>
               HOME
            </Link>
            <Link to="/services" className="a" onClick={() => setIsOpen(false)}>
               SERVICES
            </Link>
            <Link to="/about" className="a" onClick={() => setIsOpen(false)}>
               ABOUT US
            </Link>
            <Link to="/contact" className="a" onClick={() => setIsOpen(false)}>
               CONTACT
            </Link>
         </div>

         <div className={`nav-toggle ${isOpen && 'open'}`} onClick={() => setIsOpen(!isOpen)}>
            <div className="bar"></div>
         </div>
      </div>
   );
};

export default Header;
